@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Montserrat", serif;
}

.main-component{
  display: flex;
}

.image-container{
  width: 50%;
  margin-left: 50px;
}
input[type="radio"] {
  display: none;
}

.container {
  width: 100%;
  max-width: 800px;
  max-height: 600px;
  height: 100%;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.cards {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.card {
  position: absolute;
  width: 60%;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  transition: transform 0.4s ease, opacity 0.4s ease, z-index 0.4s ease;
  cursor: pointer;
  opacity: 0;
  transform: translateX(100%) scale(0.8);
  z-index: 0;
}

.container img {
  height: 450px;
  width: 600px;
}

img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}


.property-description {
  width: 45%;
  max-height: 80vh;
  overflow-y: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}


/* Styling for description items */

.property-description {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  margin: 0 auto;
  word-wrap: break-word;  /* Ensures text wraps within the container */
  overflow-wrap: break-word;
}

.code {
  background-color: #e2e8f0;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

.code h2 {
  font-size: 18px;
  color: #2d3748;
  margin: 0;
}

.description-item {
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-bottom: 15px;
  transition: background-color 0.3s, box-shadow 0.3s;
  word-wrap: break-word; 
  overflow-wrap: break-word;
}

.description-item:hover {
  background-color: #f3f4f6;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); 
  transform: translateY(-3px); 
  border-color: #cbd5e1;
}

.description-key {
  background-color: #e2e8f0;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  color: #2d3748;
  font-weight: bold;
  margin-bottom: 8px;
}

.description-value {
  color: #4a5568;
  font-style: italic;
  margin-left: 5px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  background-color: #000231;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.rows-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.row-key {
  background-color:  #d6dbdf ;
  padding: 8px 12px;
  border-radius: 6px;
  font-weight: 600;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  min-width: 120px;
  width: 150px;
  justify-content: center;
}

.row-value {
  background-color: #f1f5f9;
  padding: 8px 12px;
  border-radius: 6px;
  font-weight: 500;
  color: #374151;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  min-width: 150px;
  justify-content: center;
}


.nested-el{
  background-color: #e2e8f0;
  padding: 10px;
  border-radius: 5px;
}


.nested-item {
  padding: 10px;
  background-color: #f7fafc;
  border-radius: 5px;
  margin-bottom: 8px;
  border: 1px solid #edf2f7;
  transition: background-color 0.3s, border-color 0.3s;
  word-wrap: break-word;
  overflow-wrap: break-word;
  border-color: #cbd5e1;
}

.nested-item:hover {
  background-color: #f3f4f6;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); 
  transform: translateY(-3px); 
  border-color: #cbd5e1;
}

.nested-key {
  color: #2C2C2C;
  font-weight: bold;
  display: flex;
  justify-content: center;
  background-color: #e2e8f0;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  font-size: 20px;
}

.nested-value {
  color: #4a5568;
  font-style: italic;
}

.nested-item p {
  margin: 5px 0;
  word-wrap: break-word;
}


/* Loading container */
.loading-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

/* Carousel image adjustments */
.carousel-item img {
	max-height: 100%;
	width: auto;
	object-fit: cover;
}


@media (max-width: 768px) {
  .main-component {
    flex-direction: column; /* Stack the divs vertically */
  }

  .image-container{
    width: 100%;
    height: 30vh;
    margin-bottom: 20px;
  }

  .container img {
    width: 300px;
    height: 225px;
  }

  .property-description {
    width: 100%; /* Make the property description take full width */
    padding: 15px;
  }

  .container {
    width: 100%;
    max-width: 800px;
    max-height: 200px;
  }

  .cards {
    flex-direction: column; /* Stack the cards vertically */
    align-items: center;
    margin-left: 128px;
  }

  .card {
    width: 70%; /* Make the card take full width */
    margin: 10px 0;
  }

  .property-description{
    max-width: 350px;
    max-height: auto;
    overflow-y: none;
  }

  .row-key {
    background-color:  #d6dbdf ;
    padding: 8px 12px;
    border-radius: 6px;
    font-weight: 600;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    min-width: 50px;
    width: 100px;
    justify-content: center;
    font-size: 10px;
  }
  
  .row-value {
    background-color: #f1f5f9;
    padding: 8px 12px;
    border-radius: 6px;
    font-weight: 500;
    color: #374151;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    min-width: 50px;
    width: 100px;
    font-size: 10px;
    justify-content: center;
  }
}
