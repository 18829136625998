.selector {
    margin: 20px 0;
    font-family: 'Arial', sans-serif;
    position: relative;
    z-index: 10;


    @media (max-width: 768px) {
      margin: 15px 0;
    }

    @media (max-width: 480px) {
        margin: 10px 0;
    }
  }

  
  .custom-option {
    padding: 10px;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
    position: sticky;
  
    &:hover {
      background-color: #e6f7ff;
    }
  
    .event-label {
      font-weight: bold;
      font-size: 1.1em;


      @media (max-width: 768px) {
        font-size: 1em;
      }

      @media (max-width: 480px) {
        font-size: 0.9em;
      }
    }
  
    .event-time {
      color: #888;
      font-size: 0.9em;
      margin-top: 5px;

      @media (max-width: 768px) {
        font-size: 0.85em;
      }

      @media (max-width: 480px) {
        font-size: 0.8em;
      }
    }
  
    .includes {
      margin-top: 10px;
      display: flex;
      gap: 10px;

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 5px;
      }
  
      .href-prefix {
        background-color: #f0f0f0;
        padding: 2px 6px;
        border-radius: 4px;
        font-size: 0.85em;


        @media (max-width: 768px) {
          font-size: 0.8em;
        }

        @media (max-width: 480px) {
          font-size: 0.75em;
        }
      }
    }
  }
  
  .Select__control {
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    box-shadow: none;
    transition: all 0.3s ease;
  
    &:hover {
      border-color: #b3b3b3;
    }


    @media (max-width: 768px) {
      border-radius: 6px;
    }

    @media (max-width: 480px) {
      border-radius: 5px;
    } 
  }
  
  .Select__option--is-selected {
    background-color: #1890ff;
    color: white;
  }

  