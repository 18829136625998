.bet{
    height: -webkit-fill-available;
    
    .bets {
        display: flex;
        margin: 0 auto;
        align-items: center;
        padding: 4px;
        margin-bottom: 5px;
        border: 1px ridge;
        font-size: 12px;
        font-family: system-ui;
        transition: background-color 0.5s;
        position: sticky;
        top:100px;
        background-color: white;
        height: 30px;
        box-sizing: border-box;
        border: 1px solid black;
        z-index: 5;
    }
    
    .icon-container {
        font-size: 20px;
        margin-left: auto;
        margin-right: 15px;
    }
    
    .loader-container {
        position: absolute;
        align-items: center;
        right: 70px;
        margin-top: 20px;
    }
    
    .charts {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        table{
            border-collapse: collapse;
            caption {
                font-size: 1.5em;           /* Make the caption/title larger */
                font-weight: bold;
                margin-bottom: 10px;        /* Adds space between the title and the table */
                position: sticky;
                top: 130px;                     /* Sticks the header to the top */
                background-color: #f2f2f2;  /* Ensure background color persists */
                z-index: 1;    
                box-sizing: border-box;
                height: 30px;
                border-bottom: 1px solid black;
            }
            thead th {
                position: sticky;
                top: 160px;                     /* Sticks the header to the top */
                background-color: #f2f2f2;  /* Ensure background color persists */
                z-index: 1;                 /* Keeps the header above table rows */
            }
          
        }
        .table{
            text-align: center;
            font-weight: 100;
            th, td {
                border: 1px solid black; /* Adds border to cells */
                padding: 8px;            /* Adds some padding for readability */
                text-align: center;        /* Aligns text to the left */
                font-weight: 400;
            }
        }
    }
    
    .bets:hover {
        background-color: rgb(180, 180, 180);
        cursor: pointer;
    }
    

    @media (max-width: 768px) {
        .bets {
            flex-direction: column;
            font-size: 10px;
            padding: 6px;
        }

        .icon-container {
            font-size: 16px;
            margin-right: 5px;
        }

        .loader-container {
            right: 10px;
            margin-top: 10px;
        }

        .charts {
            justify-content: center;

            table {
                width: 100%;
                caption {
                    font-size: 1.2em;
                    height: auto;         /* Make the caption/title larger */
                }

                thead th {
                    font-size: 12px;
                    top: 140px;
                }
            }
        }

        .table {
            font-size: 10px;
            th, td {
                padding: 5px;
            }
        }
    }

    @media (max-width: 480px) {
        .bets {
            font-size: 9px;
            padding: 8px;
        }

        .icon-container {
            font-size: 14px;
            z-index: 0;
        }

        .charts {
            table {
                caption {
                    font-size: 1em;
                }

                thead th {
                    font-size: 10px;
                }
            }
        }

        .table {
            font-size: 9px;
            th, td {
                padding: 3px;
            }
        }
    }
}
