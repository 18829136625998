.container__slider {
    position: relative;
    width: 50vw;
    height: 60vh;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: black;
    border-radius: 15px;
    margin-left: 30px;
  }
  
  .container__slider > button {
    background-color: transparent;
    outline: none;
    border: 0;
    color: whitesmoke;
    padding: 20px;
    font-size: 40px;
    cursor: pointer;
  }
  
  .container__slider > button:active {
    color: black;
  }
  
  .slider__btn-next {
    position: absolute;
    right: 0;
  }
  
  .slider__btn-prev {
    position: absolute;
    left: 0;
  }
  
  .container__slider__links {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .container__slider__links-small {
    position: relative;
    width: 15px;
    height: 15px;
    padding: 0;
    border-radius: 50%;
    outline: none;
    border: 0;
    background-color: white;
    border: 1px solid grey;
    transition: background-color 1s ease;
    margin: 0 2px;
    cursor: pointer;
  }
  
  .container__slider__links-small-active {
    background-color: black;
  }
  
  .slider__item {
    box-sizing: border-box;
    min-width: 100%;
    height: auto;
    overflow: hidden;
    object-fit: cover;
    height: auto;
    transform: translateX(0);
    transition: transform 1s ease;
  }
  
  .slider__item {
    transition: transform .2s ease;
  }
  
  .slider__item img {
    width: 100%;
    height: auto;
  }
  
  .img-container{
    display: block;
  }

  .mini-images {
    display: flex;
    width: 50vw;
    margin-left: 30px;
    margin-top: 30px;
    overflow-x: auto;
    scrollbar-width: thin;
  }
  
  .mini-images img {
    width: 100px;
    height: 100px;
    margin: 10px;
    cursor: pointer;
  }
  

  @media (max-width: 768px) {
    .container__slider{
      position: relative;
      width: 84vw;
      height: 30vh;
      padding: 0;
      overflow: hidden;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      background-color: black;
      border-radius: 15px;
    }

    .mini-images{
      display: flex;
      width: 85vw;
      margin-left: 30px;
      margin-top: 30px;
      overflow-x: auto;
      scrollbar-width: thin;
    }
  }